import { t } from 'i18next';
import { format, parse } from 'date-fns';
import { CountryType, PhoneCountryCodeType } from '@bus/models';
import { APP_COUNTRY } from '../config';

export const baseUIDateFormat = 'dd/MM/yyyy';

export const formatPrice = (price: number | null | undefined) => {
  if (typeof price !== 'number') {
    return '';
  }
  return `${price.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })} ${t('common:shoppingCart:currencySymbol')}`;
};

export const parseDate = (dateString: string) => parse(dateString, baseUIDateFormat, new Date());

export const formatDateToUIFormat = (dateString: string, expectedFormat = baseUIDateFormat) => {
  return format(parse(dateString, expectedFormat, new Date()), expectedFormat);
};

export const getPhoneCountryCodeFromIDBrokerData = (
  idpCountryCodePhoneNumber?: string
): PhoneCountryCodeType | undefined => {
  if (!idpCountryCodePhoneNumber) return;

  const code = `+${idpCountryCodePhoneNumber}` as PhoneCountryCodeType;

  if (!Object.values(PhoneCountryCodeType).includes(code)) {
    return getDefaultCountryCode();
  }

  return code;
};
export const getDefaultCountryCode = () => {
  switch (APP_COUNTRY) {
    case CountryType.ES:
      return PhoneCountryCodeType.Spain;
    case CountryType.FR:
      return PhoneCountryCodeType.France;
    default:
      throw new Error(`Country ${APP_COUNTRY} not supported`);
  }
};

export function convertBffDateToUiDate(date: string | undefined): string {
  if (!date) {
    return '';
  }

  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  return format(parsedDate, baseUIDateFormat);
}
