import { Session } from '../../types/common/types';
import { RootStateFR } from '../../types/fr/types';
import {
  getDataVerificationInfoFromDataStorageData,
  getCustomerInfoFromDataStorageData,
  getSummaryConsentsInfoFromDataStorageData,
  getDealerDataFromDataStorageData
} from '../../mappers/fr/data-storage-to-form-data.mapper';

export const getSessionInitialState = (): Session | null => {
  const isNew = window.location.search.includes('id=');
  if (isNew) {
    sessionStorage.removeItem('subint/session');
  }
  const sessionJSON = sessionStorage.getItem('subint/session') as string;
  return isNew || !sessionJSON ? null : (JSON.parse(sessionJSON) as Session);
};

export const initialStateFr: RootStateFR = {
  dataStorage: null,

  customerInfo: getCustomerInfoFromDataStorageData(),
  dataVerification: getDataVerificationInfoFromDataStorageData(),
  summaryConsentsData: getSummaryConsentsInfoFromDataStorageData(),
  dealerData: getDealerDataFromDataStorageData(),

  appError: null,
  session: getSessionInitialState(),
  transactionStatus: null,
  ui: {
    isDataStorageDataLoading: false,
    isDataStorageDataLoaded: false,
    isTlsSupported: true,
    isTlsCheckInProgress: false,
    paymentAuthorizationStatus: null,
    isGlobalLoading: false
  },
  keycloak: {
    customerData: {},
    idp: '',
    idpHint: '',
    isLogged: false,
    trackAuthSuccess: false
  },
  persistedFormState: {}
};
