import { AddressType, CountryCodeType, EmailType, IdentityDocumentType, SalutationType } from '@bus/models';
import { DealerData } from '../../types/common/types';
import {
  CustomerDataFr,
  DataStorageData,
  DataVerification,
  LocalConsentType,
  PrimaryAddress
} from '../../../models/data-storage.model';
import { APP_COUNTRY } from '../../../config';
import { CustomerInfoFr } from '../../types/fr/types';
import { convertBffDateToUiDate, getDefaultCountryCode } from '../../../utilities/formatters';

const convertBffIdentityDocumentsToUiIdentityDocument = (
  identityDocuments: CustomerDataFr['identityDocuments'] | undefined
): CustomerInfoFr['identityDocuments'] => {
  if (!identityDocuments?.length) {
    return [
      {
        identityDocumentType: IdentityDocumentType.DRIVER_LICENSE,
        identityDocumentId: '',
        issuingCountry: (APP_COUNTRY.toUpperCase() as CountryCodeType) || CountryCodeType.France,
        issuedDate: '',
        issuingAuthority: ''
      }
    ];
  }
  return [
    {
      identityDocumentType: IdentityDocumentType.DRIVER_LICENSE,
      identityDocumentId: identityDocuments[0].identityDocumentId ?? '',
      issuingCountry: identityDocuments[0].issuingCountry ?? CountryCodeType.France,
      issuingAuthority: identityDocuments[0].issuingAuthority ?? '',
      issuedDate: convertBffDateToUiDate(identityDocuments[0].issuedDate)
    }
  ];
};

export const getCustomerInfoFromDataStorageData = (dataStorageData?: DataStorageData): CustomerInfoFr => {
  const consentData = dataStorageData?.customerData?.consentData?.length
    ? dataStorageData.customerData.consentData
    : [
        {
          consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
        },
        {
          consentType: LocalConsentType.DATA_PRIVACY,
          isGiven: false
        },
        {
          consentType: LocalConsentType.PHONE_SUPPORT,
          isGiven: true
        }
      ];

  return {
    salutation: dataStorageData?.customerData?.salutation ?? ('' as SalutationType),
    firstName: dataStorageData?.customerData?.firstName ?? '',
    lastName: dataStorageData?.customerData?.lastName ?? '',
    dateOfBirth: convertBffDateToUiDate(dataStorageData?.customerData?.dateOfBirth),
    stateOfBirth: (dataStorageData?.customerData as CustomerDataFr)?.stateOfBirth ?? CountryCodeType.France,
    placeOfBirth: (dataStorageData?.customerData as CustomerDataFr)?.placeOfBirth ?? '',
    identityDocuments: convertBffIdentityDocumentsToUiIdentityDocument(
      dataStorageData?.customerData?.identityDocuments as CustomerDataFr['identityDocuments']
    ),
    addresses: dataStorageData?.customerData?.addresses ?? [
      getEmptyAddressValue(AddressType.PRIMARY) as PrimaryAddress
    ],
    contactData: {
      emails: dataStorageData?.customerData?.contactData?.emails ?? [
        {
          type: EmailType.PERSONAL_PRIMARY,
          emailAddress: ''
        }
      ],
      mobilePhoneCountryCode:
        dataStorageData?.customerData?.contactData?.mobilePhoneCountryCode ?? getDefaultCountryCode(),
      mobilePhoneNumber: dataStorageData?.customerData?.contactData?.mobilePhoneNumber ?? ''
    },
    consentData: consentData
  };
};

export const getDealerDataFromDataStorageData = (dataStorageData?: DataStorageData): DealerData => {
  return {
    remunerationCompanyId: dataStorageData?.dealerData?.remunerationCompanyId || ''
  };
};

export function getEmptyAddressValue(addressType: AddressType) {
  return {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    countryCode: CountryCodeType.France,
    addressType: addressType
  };
}

export const getDataVerificationInfoFromDataStorageData = (dataStorageData?: DataStorageData): DataVerification => {
  return {
    emailAddressVerification: dataStorageData?.customerData?.contactData?.emailAddressVerification ?? [],
    mobilePhoneVerification: dataStorageData?.customerData?.contactData?.mobilePhoneVerification ?? []
  };
};

export const getSummaryConsentsInfoFromDataStorageData = (dataStorageData?: DataStorageData) => {
  const marketingAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.MARKETING_ADS
  );
  const phoneAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.PHONE_ADS
  );
  const dataSharingAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.DATA_SHARING_ADS
  );
  const termsAndConditionsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.TERMS_AND_CONDITIONS
  );

  return {
    consentData: [
      { consentType: LocalConsentType.MARKETING_ADS, isGiven: marketingAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.PHONE_ADS, isGiven: phoneAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.DATA_SHARING_ADS, isGiven: dataSharingAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.TERMS_AND_CONDITIONS, isGiven: termsAndConditionsConsent?.isGiven ?? false }
    ]
  };
};
