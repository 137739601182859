import {
  AddressType,
  BrandType,
  CountryCodeType,
  CountryType,
  CurrencyType,
  CustomerRoleType,
  DeliveryCostType,
  DeliveryType,
  EmailType,
  EntryPointType,
  IdentifierSourceType,
  IdentityDocumentType,
  ManufacturerType,
  MileageUnitType,
  PhoneCountryCodeType,
  PricingElementUnitType,
  ProductType,
  SalutationType,
  SpecificUnitType,
  VehicleViewType
} from '@bus/models';

export type DataStorageObject = {
  id: string;
  product: ProductType;
  country: 'fr';
  entryPoint: EntryPointType;
  brand: BrandType;
  data: DataStorageData;
  creationDate: string;
};

export type DataStorageData = {
  transaction: Transaction;
  customerData?: CustomerDataEs | CustomerDataFr;
  financialProduct: FinancialProduct;
  custom?: CustomData;
  dealerData?: DealerData;
};

export type SalesChannel = {
  redirectUrl?: string;
};

export interface ExternalTransactionIdentifier {
  id: string;
  type: IdentifierSourceType.EUROPCAR_PAYMENT_SESSION_ID;
}

export type Transaction = {
  id: string;
  brand: BrandType;
  country: CountryType;
  status: TransactionStatus;
  links: Link[];
  product: ProductType;
  externalTransactionIdentifiers?: ExternalTransactionIdentifier[];
};

export interface Link {
  url: string;
  type: LocalLinkType;
}

export enum TransactionStatus {
  INITIALIZED = 'INITIALIZED',
  LEAD_SUBMITTED = 'LEAD_SUBMITTED',
  APPLICATIONFORM_STARTED = 'APPLICATIONFORM_STARTED',
  CIC_INITIALIZED = 'CIC_INITIALIZED',
  CIC_SUCCESSFUL = 'CIC_SUCCESSFUL',
  APPLICATIONFORM_COMPLETED = 'APPLICATIONFORM_COMPLETED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  CONTRACT_CONFIRMED = 'CONTRACT_CONFIRMED'
}

export enum VehicleCodeType {
  AC_REFERENCE_NUMBER = 'AC_REFERENCE_NUMBER',
  EUROPCAR_VEHICLE_REFERENCE = 'EUROPCAR_VEHICLE_REFERENCE'
}

export enum SpecificationType {
  CUSTOMER_MIN_AGE = 'CUSTOMER_MIN_AGE',
  DRIVER_LICENSE_PERIOD_MIN = 'DRIVER_LICENSE_PERIOD_MIN'
}

export enum PricingElementType {
  BASE = 'BASE',
  ADDONS = 'ADDONS',
  ONE_TIME = 'ONE_TIME',
  EXCESS_KILOMETERS = 'EXCESS_KILOMETERS',
  ADMINISTRATIVE_COSTS = 'ADMINISTRATIVE_COSTS',
  EXCESS = 'EXCESS'
}

export enum MileageIntervalType {
  TOTAL = 'TOTAL',
  DAY = 'DAY',
  MONTH = 'MONTH'
}

export type DriverLicenseCommon = {
  identityDocumentType: IdentityDocumentType.DRIVER_LICENSE;
  issuingCountry: CountryCodeType;
  issuedDate: string;
};

export type DriverLicenseEs = DriverLicenseCommon & {
  expiryDate: string;
};

export type DriverLicenseFr = DriverLicenseCommon & {
  identityDocumentId: string;
  issuingAuthority: string;
};

export interface IdentityDocument {
  identityDocumentId: string;
  identityDocumentType: IdentityDocumentType.ID_CARD | IdentityDocumentType.PASSPORT | '';
}

export interface DealerData {
  remunerationCompanyId?: string;
}

type CustomerDataCommon = {
  salutation?: SalutationType;
  lastName?: string;
  firstName?: string;
  dateOfBirth?: string;
  maritalStatus?: string;
  contactData?: ContactData;
  addresses: [PrimaryAddress, DeliveryAddress?];
  consentData?: ConsentData[];
  role?: CustomerRoleType;
  isOnSanctionsLists?: boolean;
  identificationNumber?: string;
};

export type CustomerDataEs = CustomerDataCommon & {
  identityDocuments: [IdentityDocument, DriverLicenseEs];
};

export type CustomerDataFr = CustomerDataCommon & {
  placeOfBirth?: string;
  stateOfBirth?: CountryCodeType;
  identityDocuments: [DriverLicenseFr];
};

export type CustomerData = CustomerDataEs | CustomerDataFr;

interface EmailAddressVerificationDetail {
  status: 'COLLECTED' | 'VERIFIED';
}

interface MobilePhoneVerificationDetail {
  status: 'COLLECTED' | 'VERIFIED';
}

export interface DataVerification {
  mobilePhoneVerification?: MobilePhoneVerificationDetail[];
  emailAddressVerification?: EmailAddressVerificationDetail[];
}

export interface ContactData extends DataVerification {
  emails: Email[];
  telephoneCountryCode?: PhoneCountryCodeType;
  telephoneNumber?: string;
  mobilePhoneCountryCode?: PhoneCountryCodeType;
  mobilePhoneNumber?: string;
}

export type Email = {
  type: EmailType;
  emailAddress: string;
};

export type ConsentData = {
  consentType: LocalConsentType;
  isGiven?: boolean;
  date?: string;
  description?: string;
  ipAddress?: string;
};

export type Address<Type> = {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  countryCode: CountryCodeType;
  addressType: Type;
};

export type PrimaryAddress = Address<AddressType.PRIMARY>;
export type DeliveryAddress = Address<AddressType.DELIVERY>;

export type FinancialProduct = {
  offers: [Offer];
};

export type Price = {
  grossAmount: number;
  currency: CurrencyType;
};

export type Qualifier = {
  name: string;
  id: string;
};

export type AdditionalProduct = {
  code: string;
  codeFamily?: 'YDF01' | string;
  additionalProductCostAmount?: Price;
  additionalMonthlyMileage?: { amount: number };
  name?: string;
  description?: string;
  duration?: number;
  qualifiers?: Qualifier[];
};

export type Offer = {
  vehicleCategory: VehicleCategory;
  offerSpecifications: [OfferSpecification];
  stations?: Station[];
  /**
   * Type of a product. Default value is 'subscription'.
   */
  productType: ProductType;
  pricings: [Pricing];
  additionalProducts: AdditionalProduct[];
  deliveryOptions: [DeliveryOption];
};

export type VehicleCategory = {
  codes: VehicleCode[];
  model: VehicleModel;
};

export type VehicleModel = {
  manufacturer: ManufacturerType;
  name: string;
  description: string;
  vehicleImages: VehicleImage[];
};

export type VehicleCode = {
  type: VehicleCodeType;
  code: string;
};

export type OfferSpecification = {
  type: SpecificationType;
  valueAmount: number;
};

export type Station = {
  stationId?: string;
  name?: string;
  addresses?: Address<AddressType>[];
  contactData?: ContactData;
};

export type DeliveryCostComponent = {
  costType: DeliveryCostType;
  deliveryCostAmount: DeliveryCostAmount;
};

export type DeliveryCostAmount = {
  grossAmount: number;
  currency: CurrencyType;
};

export interface Pricing {
  elements: PricingElement[];
  total: PricingTotal;
}

export interface PricingElement {
  type: PricingElementType;
  mileages?: Mileage[];
  price: Price;
  specificUnit?: SpecificUnitType;
  unit: PricingElementUnitType;
  minCount: number;
  maxCount: number;
}

export interface PricingTotal {
  price: Price;
  unit: number;
}

export interface Mileage {
  interval?: MileageIntervalType;
  unit?: MileageUnitType;
  value?: number;
}

export interface DeliveryOption {
  deliveryType: DeliveryType;
  deliveryDate: string;
  deliveryCostComponents: [DeliveryCostComponent];
}

export type VehicleImage = {
  url: string;
  view: VehicleViewType;
};

export type CustomData = {
  continuationTimestamp?: Date;
  quoteValidity?: {
    date: string;
    dateTime: string;
  };
};

export enum LocalConsentType {
  MARKETING_ADS = 'MARKETING_ADS',
  DATA_PRIVACY = 'DATA_PRIVACY',
  DATA_SHARING_ADS = 'DATA_SHARING_ADS',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  ALLOW_UNENCRYPTED_EMAILS = 'ALLOW_UNENCRYPTED_EMAILS',
  PHONE_ADS = 'PHONE_ADS',
  PHONE_SUPPORT = 'PHONE_SUPPORT'
}

export enum LocalLinkType {
  CALLBACK_STOREFRONT = 'CALLBACK_STOREFRONT',
  ENTRY_POINT = 'ENTRY_POINT',
  REDIRECT = 'REDIRECT',
  REDIRECT_BRAND = 'REDIRECT_BRAND',
  CONTINUE_IN_CHECKOUT = 'CONTINUE_IN_CHECKOUT',
  STOREFRONT_REDIRECT = 'STOREFRONT_REDIRECT'
}
