import { AddressType, CountryCodeType, EmailType, IdentityDocumentType, SalutationType } from '@bus/models';
import { DealerData } from '../../types/common/types';
import {
  CustomerDataEs,
  DataStorageData,
  DataVerification,
  LocalConsentType,
  PrimaryAddress
} from '../../../models/data-storage.model';
import { convertBffDateToUiDate, getDefaultCountryCode } from '../../../utilities/formatters';
import { APP_COUNTRY } from '../../../config';
import { CustomerInfoEs } from '../../types/es/types';

const convertBffIdentityDocumentsToUiIdentityDocument = (
  identityDocuments: CustomerDataEs['identityDocuments'] | undefined
): CustomerInfoEs['identityDocuments'] => {
  return identityDocuments?.length
    ? [
        {
          identityDocumentType: identityDocuments[0].identityDocumentType ?? '',
          identityDocumentId: identityDocuments[0].identityDocumentId
        },
        {
          identityDocumentType: IdentityDocumentType.DRIVER_LICENSE,
          issuingCountry: identityDocuments[1].issuingCountry,
          issuedDate: convertBffDateToUiDate(identityDocuments[1].issuedDate),
          expiryDate: convertBffDateToUiDate(identityDocuments[1].expiryDate)
        }
      ]
    : [
        {
          identityDocumentType: '',
          identityDocumentId: ''
        },
        {
          identityDocumentType: IdentityDocumentType.DRIVER_LICENSE,
          issuingCountry: APP_COUNTRY.toUpperCase() as CountryCodeType,
          issuedDate: '',
          expiryDate: ''
        }
      ];
};

export const getCustomerInfoFromDataStorageData = (dataStorageData?: DataStorageData): CustomerInfoEs => {
  const allowUnencryptedEmailsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
  ) ?? { consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS };

  const consentData = [allowUnencryptedEmailsConsent];

  return {
    salutation: dataStorageData?.customerData?.salutation ?? ('' as SalutationType),
    firstName: dataStorageData?.customerData?.firstName ?? '',
    lastName: dataStorageData?.customerData?.lastName ?? '',
    dateOfBirth: convertBffDateToUiDate(dataStorageData?.customerData?.dateOfBirth),
    identityDocuments: convertBffIdentityDocumentsToUiIdentityDocument(
      dataStorageData?.customerData?.identityDocuments as CustomerDataEs['identityDocuments']
    ),
    addresses: dataStorageData?.customerData?.addresses ?? [
      getEmptyAddressValue(AddressType.PRIMARY) as PrimaryAddress
    ],
    contactData: {
      emails: dataStorageData?.customerData?.contactData?.emails ?? [
        {
          type: EmailType.PERSONAL_PRIMARY,
          emailAddress: ''
        }
      ],
      mobilePhoneCountryCode:
        dataStorageData?.customerData?.contactData?.mobilePhoneCountryCode ?? getDefaultCountryCode(),
      mobilePhoneNumber: dataStorageData?.customerData?.contactData?.mobilePhoneNumber ?? ''
    },
    consentData
  };
};

export const getDealerDataFromDataStorageData = (dataStorageData?: DataStorageData): DealerData => {
  return {
    remunerationCompanyId: dataStorageData?.dealerData?.remunerationCompanyId || ''
  };
};

export function getEmptyAddressValue(addressType: AddressType) {
  return {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    countryCode: CountryCodeType.Spain,
    addressType: addressType
  };
}

export const getDataVerificationInfoFromDataStorageData = (dataStorageData?: DataStorageData): DataVerification => {
  return {
    emailAddressVerification: dataStorageData?.customerData?.contactData?.emailAddressVerification ?? [],
    mobilePhoneVerification: dataStorageData?.customerData?.contactData?.mobilePhoneVerification ?? []
  };
};

export const getSummaryConsentsInfoFromDataStorageData = (dataStorageData?: DataStorageData) => {
  const marketingAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.MARKETING_ADS
  );
  const phoneAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.PHONE_ADS
  );
  const dataPrivacyConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.DATA_PRIVACY
  );
  const termsAndConditionsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.TERMS_AND_CONDITIONS
  );

  return {
    consentData: [
      { consentType: LocalConsentType.MARKETING_ADS, isGiven: marketingAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.PHONE_ADS, isGiven: phoneAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.DATA_PRIVACY, isGiven: dataPrivacyConsent?.isGiven ?? false },
      { consentType: LocalConsentType.TERMS_AND_CONDITIONS, isGiven: termsAndConditionsConsent?.isGiven ?? false }
    ]
  };
};
