// These must be the first lines.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducer from './redux/reducer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Spinner } from '@vwfs-bronson/bronson-react';

import * as serviceWorker from './serviceWorker';
import initI18n from './i18n';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk] as any;

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));
const rootElement = document.getElementById('root');

const ConnectedApp = React.lazy(() => import('./App'));

const Init: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    Promise.all([initI18n()]).then(() => {
      setLoaded(true);
    });
  }, []);

  return loaded ? (
    <React.Suspense>
      <Provider store={store}>
        <ConnectedApp />
      </Provider>
    </React.Suspense>
  ) : (
    <Spinner fullPage />
  );
};

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Init />);
}

serviceWorker.unregister();
