import { continueLaterLocalization } from '@vwfs-its/sf-sac-frontend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { merge } from 'lodash';
import { APP_BRAND, APP_COUNTRY, APP_LANG } from './config';

const transNamespaces = [
  'customerInfo',
  'common',
  'confirmationPage',
  'errorPage',
  'summaryPage',
  'payment',
  'applicationPage',
  'contactModal',
  'mailDispatchModal',
  'cicDataVerification',
  'validation'
] as const;

const localization = continueLaterLocalization({
  locale: APP_LANG,
  brandName: { BRAND_NAME: APP_BRAND }
});

i18n.use(initReactI18next).init({
  resources: {},
  lng: APP_LANG,
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  }
});

i18n.addResourceBundle(APP_LANG, 'sf-sac', localization, true, true);

export default async () => {
  await Promise.all(
    transNamespaces.map(async ns => {
      const generic = await import(`./resources/locales/${APP_COUNTRY}/generic/${ns}.json`);
      const brandTranslations = await import(`./resources/locales/${APP_COUNTRY}/${APP_BRAND}/${ns}.json`)
        .then(brandTrans => brandTrans)
        .catch(() => ({}));

      const mergedNsTranslations = merge(generic.default, brandTranslations.default);

      i18n.addResourceBundle(APP_LANG, ns, mergedNsTranslations, true, true);
    })
  );
};
