import { CountryType } from '@bus/models';

export const AVAILABLE_COUNTRIES = [CountryType.ES, CountryType.FR];

export const APP_COUNTRY = process.env.REACT_APP_WEBSITE_COUNTRY;
export const APP_BRAND = process.env.REACT_APP_WEBSITE_BRAND;
export const APP_LANG = process.env.REACT_APP_WEBSITE_LANG;
export const APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
export const APP_LOCALE = process.env.REACT_APP_LOCALE;

export let SECURITY_DEPOSIT_VALUE: number;
if (APP_COUNTRY === CountryType.ES) {
  SECURITY_DEPOSIT_VALUE = 300;
} else if (APP_COUNTRY === CountryType.FR) {
  SECURITY_DEPOSIT_VALUE = 750;
}
