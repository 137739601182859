import actionTypes from './actionTypes';
import { initialStateEs } from './initial-state/es/initialState';
import { initialStateFr } from './initial-state/fr/intialState';
import { APP_COUNTRY } from '../config';
import { RootState } from './types';

const initialStateMapping: Record<typeof APP_COUNTRY, RootState> = {
  es: initialStateEs,
  fr: initialStateFr
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const initialState = initialStateMapping[APP_COUNTRY]!;

export const SESSION_CACHE_KEY = 'subint/session';

export default (state = initialState, action: any) => {
  const payload = action.payload?.body ? JSON.parse(action.payload.body) : action.payload;
  switch (action.type) {
    case actionTypes.SET_APP_ERROR:
      return {
        ...state,
        appError: payload,
        [action.error]: true
      };
    case actionTypes.SET_SESSION:
      return {
        ...state,
        session: payload
      };
    case actionTypes.SET_DATA_STORAGE:
      return {
        ...state,
        dataStorage: payload,
        transactionStatus: payload.data.transaction.status
      };
    case actionTypes.SET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: payload
      };
    case actionTypes.SET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: payload
      };
    case actionTypes.SET_DATA_VERIFICATION_INFO:
      return {
        ...state,
        dataVerification: payload
      };
    case actionTypes.SET_DATA_STORAGE_DATA_IS_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDataStorageDataLoading: payload
        }
      };
    case actionTypes.SET_PAYMENT_AUTHORIZATION_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentAuthorizationStatus: payload
        }
      };
    case actionTypes.SET_IS_TLS_SUPPORTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTlsSupported: payload
        }
      };
    case actionTypes.SET_IS_TLS_CHECK_IN_PROGRESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTlsCheckInProgress: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_DATA:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          customerData: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_IDP:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          idp: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_IDP_HINT:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          idpHint: payload
        }
      };
    case actionTypes.SET_IS_LOGGED:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          isLogged: payload
        }
      };
    case actionTypes.SET_TRACK_AUTH_SUCCESS:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          trackAuthSuccess: payload
        }
      };
    case actionTypes.SET_PERSISTED_FORM_STATE:
      return {
        ...state,
        persistedFormState: payload
      };
    case actionTypes.SET_GLOBAL_STATE_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isGlobalLoading: payload
        }
      };
    case actionTypes.SET_DEALER_DATA:
      return {
        ...state,
        dealerData: {
          ...state.dealerData,
          ...payload
        }
      };
    default:
      return state;
  }
};
